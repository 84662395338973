<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'">
    <v-row no-gutters>
      <v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mobile-padding'">
        <v-card class="game-page-card-menu hidden-md-and-up text-center">
          <v-icon class="mobile-header-navArrow" @click="goToPrevGamePage" large>mdi-chevron-left</v-icon>
          {{ this.gameTypePageName }}
          <v-icon class="mobile-header-navArrow" @click="goToNextGamePage" large>mdi-chevron-right</v-icon>
        </v-card>
        <v-card class="hidden-md-and-up ">
          <div class="mobile-provider-list">
            <div class="mobile-provider-card all-provider-div" @click="goToVendor('all')" :class="searchCriteria.provider == 'all' ? 'selected' : ''">
              <span class="all-provider text-center">{{ $t(`label.all`) }}</span>
            </div>
            <div class="mobile-provider-card" v-for="provider in gameProvider" :key="provider.providerCode" @click="goToVendor(provider.providerCode)">
              <v-img
                v-if="searchCriteria.provider != provider.providerCode.toLowerCase()"
                class="provider-card-img"
                :src="`${mediaUrl}/vendor-card/${providerSelected}/${provider.providerCode}_avatar.png`"
                width="auto"
                height="60"
                max-height="60px"
              ></v-img>
              <v-img v-else class="provider-card-img" :src="`${mediaUrl}/vendor-card/${providerSelected}/${provider.providerCode}_dark.jpg`" width="auto" height="60" max-height="60px"></v-img>
              <span class="text-center mobile-vendor-name">{{ $t(`label.custom_${provider.providerCode}`) }}</span>
            </div>

            <!--            <v-card class="mobile-provider-card">-->
            <!--              <v-img title="image provider"></v-img>-->
            <!--              <span>provider 2</span>-->
            <!--            </v-card>-->
          </div>
        </v-card>
        <v-card class="mx-auto gamesCardContainer" :class="$vuetify.breakpoint.mdAndUp ? '' : 'mb-4'">
          <v-img v-if="$vuetify.breakpoint.xsOnly" class="align-end hidden-sm-and-down" :aspect-ratio="360 / 140" :src="`${mediaUrl}/game_banner/${providerSelected}.jpg`">
            <v-card-title class="white--text font-weight-bold pa-0">
              <p class="mb-0 ml-3">
                <span>
                  {{ $t(`label.${providerSelected}`) }}
                </span>
              </p>
            </v-card-title>
          </v-img>
          <v-img
            v-else
            class="align-end hidden-sm-and-down"
            style="position: relative; border-top-right-radius: 0px; border-top-left-radius: 0px;"
            :aspect-ratio="1400 / 300"
            :src="`${mediaUrl}/game_banner/${providerSelected}_new.jpg`"
          >
            <v-card-title class="white--text font-weight-bold">
              <div class="game_banner_text">
                <h3 style="font-size: 26px;">{{ $t(`label.${providerSelected}`) }}</h3>
                <p class="mb-0" style="font-size: 20px;">{{ $t(`label.${providerSelected}_h2`) }}</p>
                <p style="font-size: 12px;">{{ $t(`label.${providerSelected}_descriptions`) }}</p>
              </div>
            </v-card-title>
          </v-img>
          <div :class="$vuetify.breakpoint.mdAndUp ? 'margin-desktop' : ''">
            <v-row class="game-vendor-list-container hidden-sm-and-down " no-gutters v-if="storageGameProviderType && storageGameProviderType.find(x => x.type == providerSelected)">
              <v-col cols="12" class="text-left d-flex flex-wrap justify-start">
                <div
                  class="vendor-list-item slot-product elevation-0"
                  v-if="
                    !$vuetify.breakpoint.mdAndUp ||
                      !(
                        this.currentRouteName === this.routeName.SPORTSBOOK_BY_PROVIDER ||
                        this.currentRouteName === this.routeName.SPORTSBOOK ||
                        this.currentRouteName === this.routeName.CRICKET_BY_PROVIDER ||
                        this.currentRouteName === this.routeName.CRICKET
                      )
                  "
                >
                  <v-btn
                    height="auto"
                    min-height="40"
                    :class="searchCriteria.provider == '' || searchCriteria.provider == undefined || searchCriteria.provider == 'all' ? 'selectedProvider' : ''"
                    class="text-capitalize text-center subtitle-2 font-weight-bold my-0 vendor-list-item-button px-6"
                    @click="goToVendor('all')"
                  >
                    <v-row>
                      <!--                    <v-col cols="12" class="pa-0">-->
                      <!--                      <v-col cols="12" class="py-0 text-center">-->
                      <!--                        <v-divider v-show="searchCriteria.provider == '' || searchCriteria.provider == undefined || searchCriteria.provider == 'all'" class="vendor-menu-divider"></v-divider>-->
                      <!--                      </v-col>-->
                      <!--                    </v-col>-->
                      <v-col cols="12" class="pa-0">
                        {{ $t(`label.all`) }}
                      </v-col>
                    </v-row>
                  </v-btn>
                </div>
                <div class="vendor-list-item slot-product elevation-0" v-for="provider in storageGameProviderType.find(x => x.type == providerSelected).providers" :key="provider.providerCode">
                  <v-btn
                    height="auto"
                    min-height="40"
                    @click="handleVendorClick(provider)"
                    :class="[searchCriteria.provider === provider.providerCode ? 'selectedProvider' : '', { disableBackground: isGameMaintenance(providerSelected, provider.providerCode) }]"
                    class="text-capitalize text-center my-0 vendor-list-item-button"
                  >
                    <v-row>
                      <!--                    <v-col cols="12" class="pa-0">-->
                      <!--                      <v-col cols="12" class="py-0 text-center">-->
                      <!--                        <v-divider v-show="searchCriteria.provider == provider.providerCode" class="vendor-menu-divider"></v-divider>-->
                      <!--                      </v-col>-->
                      <!--                    </v-col>-->
                      <v-col cols="12" class="pa-0 d-flex align-center justify-center" style="gap: 3%;">
                        <v-img
                          v-if="searchCriteria.provider == provider.providerCode"
                          class="provider_btn"
                          :src="`${mediaUrl}/provider/${providerSelected}/${provider.providerCode}_selected.svg`"
                        ></v-img>
                        <v-img
                          v-else-if="isGameMaintenance(providerSelected, provider.providerCode)"
                          class="provider_btn"
                          :src="`${mediaUrl}/provider/${providerSelected}/${provider.providerCode}_maintenance.svg`"
                        ></v-img>
                        <v-img v-else class="provider_btn" :src="`${mediaUrl}/provider/${providerSelected}/${provider.providerCode}.svg`"></v-img>
                        <span v-if="isGameMaintenance(providerSelected, provider.providerCode)" style="font-family: 'Heebo-Regular', sans-serif;">
                          {{ $t(`label.underMaintenance`) }}
                        </span>
                        <span v-else style="font-family: 'Heebo-Regular', sans-serif;">{{ stringFormat('{0}', $t(`gameVendor.${provider.providerCode}_${providerSelected}_long`)) }}</span>
                      </v-col>
                    </v-row>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row class="game-category-list-container hidden-sm-and-down" align="center" no-gutters>
              <v-col cols="9" class="my-3 d-flex align-center">
                <span>{{ $t(`label.sortBy`) }}</span>
                <v-combobox
                  v-model="selectedCategoryDisplayName"
                  class="search_game_category search_size "
                  :items="categoryItems"
                  item-text="displayName"
                  :label="$t('label.search_category')"
                  item-value="displayName"
                  :clearable="true"
                  solo
                  rounded
                  dense
                  outlined
                  :return-object="false"
                  @change="updateSelectedCategory(selectedCategoryDisplayName)"
                ></v-combobox>
                <!--                <v-btn-->
                <!--                  v-for="(category, index) in categories"-->
                <!--                  :key="index"-->
                <!--                  elevation="0"-->
                <!--                  large-->
                <!--                  text-->
                <!--                  class="subtitle-2 text-capitalize sortClass"-->
                <!--                  :color="selectedCategory == category.name ? 'white' : '#ACACAC'"-->
                <!--                  :class="selectedCategory == category.name ? 'sortedClass' : ''"-->
                <!--                  tile-->
                <!--                  @click="changeGameCategory(category.name)"-->
                <!--                >-->
                <!--                  {{ $t(`gameCategory.${category.name}`) }}-->
                <!--                  &lt;!&ndash;                <v-divider v-show="index !== categories.length - 1" class="category-divider ml-10" vertical></v-divider>&ndash;&gt;-->
                <!--                </v-btn>-->
              </v-col>
              <v-col class="justify-end" cols="8" lg="3" md="6" :class="$vuetify.breakpoint.mdAndUp ? '' : 'py-0 px-3'">
                <v-combobox
                  autocomplete="off"
                  class="search_game_text pl-0 search_game_category"
                  rounded
                  background-color="#fff"
                  :items="searchableGameList"
                  :clearable="true"
                  :label="$t('label.search_game')"
                  solo
                  filled
                  prepend-inner-icon
                  @click:append="searchGame()"
                  @click:clear="searchGame()"
                  @change="searchGame()"
                  item-text="name"
                  color="title_color"
                  v-model.trim="searchValue"
                  return-object
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#959595">mdi-magnify</v-icon>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row class="hidden-md-and-up" :class="['justify-end col-12 py-0 pl-0 pr-0']" no-gutters>
              <v-col cols="12" lg="3" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'py-0' : 'py-0 px-3'">
                <v-combobox
                  autocomplete="off"
                  class="search_game_text pl-0 justify-end"
                  rounded
                  background-color="#fff"
                  :items="searchableGameList"
                  :clearable="true"
                  :label="$t('label.search_game')"
                  solo
                  filled
                  prepend-inner-icon
                  @click:append="searchGame()"
                  @click:clear="searchGame()"
                  @change="searchGame()"
                  item-text="name"
                  color="title_color"
                  v-model.trim="searchValue"
                  return-object
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#959595">mdi-magnify</v-icon>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-list class="slot-game-card-select pb-0" :class="[$vuetify.breakpoint.smAndDown ? 'px-2' : '']">
              <template v-for="game in gameList">
                <!--                default mode-->
                <v-list-item
                  v-if="
                    !$vuetify.breakpoint.mdAndUp ||
                      !(
                        $route.params.provider === 'betswiz' ||
                        $route.params.provider === 'sap' ||
                        $route.params.provider === 'ibc' ||
                        $route.params.provider === 'bti' ||
                        $route.params.provider === '9wicket' ||
                        currentRouteName === routeName.CASINO ||
                        currentRouteName === routeName.CASINO_BY_PROVIDER
                      )
                  "
                  :class="['px-0 slot-game-card-item text-center', $vuetify.breakpoint.smAndDown ? 'mb-2' : 'mb-5']"
                  :key="game.provider + game.code + game.id"
                >
                  <v-card class="elevation-0">
                    <v-badge right overlap color="light" offset-x="30" offset-y="30">
                      <template v-slot:badge>
                        <v-img :src="checkCategory(game.category)" class="new_icon_sizing" :width="$vuetify.breakpoint.smAndDown ? '30' : '40'"></v-img>
                      </template>
                      <v-avatar class="game_icon_sizing gameButton">
                        <v-img :src="game.imageCdn == null ? `${mediaUrl}/game_icons/en/${game.provider}/${game.code}_${game.id}.jpg` : `${game.imageCdn}`" />
                        <div class="slot-game-button-container">
                          <div class="slot-game-button-container-box">
                            <div class="slot-game-button-box d-flex justify-center" v-if="!isGameMaintenance(providerSelected, game.provider)">
                              <v-btn icon class="slot-game-button" @click="launchGame(game, false)">
                                <v-avatar class="game_icon_sizing" :size="$vuetify.breakpoint.mdAndDown ? '48' : '60'">
                                  <img src="/static/svg/play_btn.svg" @mouseover="hoverPlayButton($event.currentTarget, true)" @mouseleave="hoverPlayButton($event.currentTarget, false)" alt />
                                </v-avatar>
                              </v-btn>
                              <v-btn v-if="game.isDemoAvailable" icon class="slot-game-button" :class="$vuetify.breakpoint.xsOnly ? 'ml-4' : 'ml-8'" @click="launchGame(game, true)">
                                <v-avatar class="game_icon_sizing" :size="$vuetify.breakpoint.mdAndDown ? '48' : '60'">
                                  <img src="/static/svg/demo_btn.svg" @mouseover="hoverDemoButton($event.currentTarget, true)" @mouseleave="hoverDemoButton($event.currentTarget, false)" alt />
                                </v-avatar>
                              </v-btn>
                            </div>
                            <div class="slot-game-button-box maintenance text-center" v-else>
                              <span class="mt-3 full-width font-weight-bold pa-2 primary--text under-maintenance-text">{{ $t(`label.underMaintenance`) }}</span>
                            </div>
                          </div>
                        </div>
                      </v-avatar>
                    </v-badge>
                  </v-card>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card-text v-bind="attrs" v-on="on" class="pt-2 font-weight-bold slot-game-name-box text-center elevation-0">
                        {{ getGameName(game.locale) }}
                      </v-card-text>
                    </template>
                    <span>{{ getGameName(game.locale) }}</span>
                  </v-tooltip>
                </v-list-item>
                <v-list-item
                  v-else-if="$vuetify.breakpoint.mdAndUp && (currentRouteName === routeName.CASINO || currentRouteName === routeName.CASINO_BY_PROVIDER)"
                  :class="['px-0 casino-game-card-item text-center', $vuetify.breakpoint.smAndDown ? 'mb-2' : 'mb-5']"
                  :key="game.provider + game.code + game.id"
                >
                  <v-card class="elevation-0">
                    <v-badge right overlap color="light" offset-x="30" offset-y="30" class="">
                      <template v-slot:badge>
                        <v-img :src="checkCategory(game.category)" class="new_icon_sizing" :width="$vuetify.breakpoint.smAndDown ? '30' : '40'"></v-img>
                      </template>
                      <v-avatar class="game_icon_sizing gameButton">
                        <v-img :src="game.imageCdn == null ? `${mediaUrl}/game_icons/en/${game.provider}/${game.code}_${game.id}.jpg` : `${game.imageCdn}`" />
                        <div class="slot-game-button-container">
                          <div class="slot-game-button-container-box">
                            <div class="slot-game-button-box d-flex justify-center" v-if="!isGameMaintenance(providerSelected, game.provider)">
                              <v-btn icon class="slot-game-button" @click="launchGame(game, false)">
                                <v-avatar class="game_icon_sizing" :size="$vuetify.breakpoint.mdAndDown ? '48' : '60'">
                                  <img src="/static/svg/play_btn.svg" @mouseover="hoverPlayButton($event.currentTarget, true)" @mouseleave="hoverPlayButton($event.currentTarget, false)" alt />
                                </v-avatar>
                              </v-btn>
                              <v-btn v-if="game.isDemoAvailable" icon class="slot-game-button" :class="$vuetify.breakpoint.xsOnly ? 'ml-4' : 'ml-8'" @click="launchGame(game, true)">
                                <v-avatar class="game_icon_sizing" :size="$vuetify.breakpoint.mdAndDown ? '48' : '60'">
                                  <img src="/static/svg/demo_btn.svg" @mouseover="hoverDemoButton($event.currentTarget, true)" @mouseleave="hoverDemoButton($event.currentTarget, false)" alt />
                                </v-avatar>
                              </v-btn>
                            </div>
                            <div class="slot-game-button-box maintenance text-center" v-else>
                              <span class="mt-3 full-width font-weight-bold pa-2 primary--text under-maintenance-text">{{ $t(`label.underMaintenance`) }}</span>
                            </div>
                          </div>
                        </div>
                      </v-avatar>
                    </v-badge>
                  </v-card>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card-text v-bind="attrs" v-on="on" class="pt-2 font-weight-bold slot-game-name-box text-center elevation-0">
                        {{ getGameName(game.locale) }}
                      </v-card-text>
                    </template>
                    <span>{{ getGameName(game.locale) }}</span>
                  </v-tooltip>
                </v-list-item>
                <v-list-item v-else :key="game.provider + game.code + game.id" class="text-center">
                  <v-card class="elevation-0 d-flex justify-center align-center" style="width: 100%;">
                    <div style="width: 100%;">
                      <v-row no-gutters class="d-flex justify-center align-center">
                        <v-img style="width: 235px; height: auto; max-width: 235px;" :src="`${mediaUrl}/provider/logo/${game.provider}_${providerSelected}.png`"></v-img>
                      </v-row>
                      <v-row no-gutters class="pt-5">
                        <v-col cols="12" style="overflow-wrap: break-word;">
                          {{ $t(`label.${$route.params.provider}_details`) }}
                        </v-col>
                        <v-col cols="12">
                          <div class="d-flex justify-center pt-5" v-if="!isGameMaintenance(providerSelected, game.provider)">
                            <v-btn icon class="betNowButton" @click="launchGame(game, false)">
                              Bet Now
                            </v-btn>
                            <v-btn v-if="game.isDemoAvailable" icon class="betNowButton" :class="$vuetify.breakpoint.xsOnly ? 'ml-4' : 'ml-8'" @click="launchGame(game, true)">
                              Bet Now
                            </v-btn>
                          </div>
                          <div class="maintenance text-center" v-else>
                            <span class="mt-3 full-width font-weight-bold pa-2 primary--text under-maintenance-text">{{ $t(`label.underMaintenance`) }}</span>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="d-flex justify-center align-center pt-5" style="max-width: 100%;">
                        <v-img :aspect-ratio="1280 / 460" style="max-width: 80%;" :src="`${mediaUrl}/provider/logo/${game.provider}_background.jpg`"></v-img>
                      </v-row>
                    </div>
                  </v-card>
                </v-list-item>
              </template>
            </v-list>
            <p v-if="gameList.length <= 0" class="text-center text-capitalize mb-2">{{ $t(`label.noGameFound`) }}</p>
            <div class="text-center mb-6" v-if="pagination.totalPage > 1">
              <v-pagination
                class="game_pagination"
                v-model="pagination.currentPage"
                :length="pagination.totalPage"
                @input="changePage"
                :next-icon="'arrow_forward_ios'"
                :prev-icon="'arrow_back_ios'"
                :total-visible="pagination.paginationPageLimit"
              ></v-pagination>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { locale, uiHelper } from '@/util'
import { CACHE_KEY, SESSION, SHARED } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import format from 'string-format'
import { GAME_TYPE_GAMES, GAME_CATEGORIES, GAME_PROVIDERS_TYPE_CONFIG } from '@/store/game.module'
import { SEMI_TRANSFER } from '@/store/transfer.module'
import _ from 'lodash'

export default {
  name: 'TablePage',
  props: {
    providerSelected: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: instance => ({
    selectedCategoryDisplayName: '',
    gameNameList: [],
    searchValue: null,
    searchableGameList: [],
    searchedValue: '',
    isHotCls: false,
    isNewCls: false,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    gameFilterSheet: false,
    stringFormat: format,
    currentLanguage: uiHelper.getLanguage(),
    selectedGameName: '',
    selectedCategory: 'all',
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    gameProvider: '',
    gameName: '',
    filteredSlotGame: [],
    searchCriteria: {
      provider: '',
      pageNumber: 1,
      pageSize: 48,
      mobilePageSize: 21,
      tabletPageSize: 40
    },
    gameList: [],
    categories: [],
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    storageRngGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + instance.providerSelected),
    routeName: ROUTE_NAME,
    gameTypePageName: '',
    pageNav: [ROUTE_NAME.CRICKET, ROUTE_NAME.CASINO, ROUTE_NAME.SLOT, ROUTE_NAME.TABLE_GAMES, ROUTE_NAME.SPORTSBOOK, ROUTE_NAME.FISHING, ROUTE_NAME.CRASH]
  }),
  computed: {
    categoryItems() {
      return this.categories.map(category => ({
        displayName: this.$t(`gameCategory.${category.name}`),
        toFilterName: category.name
      }))
    },
    currentRouteName() {
      return this.$route.name
    },
    wallet() {
      return this.$store.state.member.walletBalance
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    TableGames() {
      if (this.providerSelected == 'sb') {
        return this.$store.state.game.SportsbookGames
      } else if (this.providerSelected == 'ld') {
        return this.$store.state.game.CasinoGames
      } else if (this.providerSelected == 'lottery') {
        return this.$store.state.game.LotteryGames
      } else if (this.providerSelected == 'table') {
        return this.$store.state.game.TableGames
      } else if (this.providerSelected == 'cricket') {
        return this.$store.state.game.CricketGames
      } else if (this.providerSelected == 'fishing') {
        return this.$store.state.game.FishingGames
      } else if (this.providerSelected == 'crash') {
        return this.$store.state.game.CrashGames
      }
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    gameCategory() {
      return this.$store.state.game.categories
    }
  },
  watch: {
    wallet() {
      return this.$store.state.member.walletBalance
    },
    $route() {
      this.getGame()
    },
    gameCategory() {
      let d = this.$store.state.game.categories
      this.categories.push({
        id: 0,
        name: 'all',
        icon: 'grid_on'
      })
      d.forEach(category => {
        let categoryName = category.name.toLowerCase()
        this.categories.push({
          id: category.id,
          name: categoryName,
          icon: categoryName == 'hot' ? 'whatshot' : categoryName == 'new' ? 'fiber_new' : ''
        })
      })
    },
    TableGames() {
      if (this.providerSelected == 'sb') {
        this.storageRngGames = this.$store.state.game.SportsbookGames
      } else if (this.providerSelected == 'fishing') {
        this.storageRngGames = this.$store.state.game.FishingGames
      } else if (this.providerSelected == 'ld') {
        this.storageRngGames = this.$store.state.game.CasinoGames
      } else if (this.providerSelected == 'lottery') {
        this.storageRngGames = this.$store.state.game.LotteryGames
      } else if (this.providerSelected == 'table') {
        this.storageRngGames = this.$store.state.game.TableGames
      } else if (this.providerSelected == 'cricket') {
        this.storageRngGames = this.$store.state.game.CricketGames
      } else if (this.providerSelected == 'crash') {
        this.storageRngGames = this.$store.state.game.CrashGames
      }
      this.pagination.currentPage = 1
      this.getFilteredGame(1)
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
      this.setProviderList()
    }
  },
  created() {
    let reload = this.changeCurrency()
    this.checkReferCode(reload)
    this.getGame()
    this.getGameCategories()

    switch (this.$route.name) {
      case this.routeName.SLOT:
      case this.routeName.SLOT_BY_PROVIDER:
      case this.routeName.SLOT_BY_PROVIDER_AND_GAMENAME:
      case this.routeName.SLOT_BY_GAMENAME:
        this.gameTypePageName = locale.getMessage('label.rng_home')
        break
      case this.routeName.CASINO:
      case this.routeName.CASINO_BY_PROVIDER:
        this.gameTypePageName = locale.getMessage('label.ld_home')
        break
      case this.routeName.SPORTSBOOK:
      case this.routeName.SPORTSBOOK_BY_PROVIDER:
        this.gameTypePageName = locale.getMessage('label.sb_home')
        break
      case this.routeName.CRICKET:
      case this.routeName.CRICKET_BY_PROVIDER:
        this.gameTypePageName = locale.getMessage('label.cricket')
        break
      case this.routeName.TABLE_GAMES:
      case this.routeName.TABLE_GAMES_BY_PROVIDER:
        this.gameTypePageName = locale.getMessage('label.table')
        break
      case this.routeName.FISHING:
      case this.routeName.FISHING_BY_PROVIDER:
        this.gameTypePageName = locale.getMessage('label.fishing')
        break
      case this.routeName.CRASH:
      case this.routeName.CRASH_BY_PROVIDER:
        this.gameTypePageName = locale.getMessage('label.crash')
        break
    }
    this.metaTag()
    this.setProviderList()
    this.changeMetaTag()
  },
  methods: {
    updateSelectedCategory(displayName) {
      const selectedItem = this.categoryItems.find(item => item.displayName === displayName)
      // this.selectedCategory = selectedItem.toFilterName // Update the selected category value
      if (selectedItem) {
        this.selectedCategory = selectedItem.toFilterName // Update the selected category value
      } else {
        console.error('Selected item not found:', displayName)
        this.selectedCategory = 'all'
      }
      this.changeGameCategory(this.selectedCategory)
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    changeCurrency() {
      let lang = this.$route.query.lang
      let cur = this.$route.query.cur
      let af = this.$route.query.af

      if (lang && cur) {
        cur = cur.toUpperCase()
        lang = lang.toLowerCase()
        /*check existing lang & cur , yes/same  = no reload ; no/not same as default : reload*/
        let currentLang = uiHelper.getLanguage()
        let currentCur = uiHelper.getCurrency()
        if (currentLang === lang && currentCur === cur) {
          return true
        } else {
          let curObj = this.languageList.filter(obj => obj.currency === cur)

          if (curObj && curObj.length > 0) {
            let langObj = curObj[0].language.filter(a => a.cd === lang)
            // .map(obj => ({ ...obj, language: obj.language.filter(c => c.cd === lang) }))
            // .filter(obj => obj.language.length > 0)
            if (langObj && langObj.length > 0) {
              uiHelper.removeCookie('language')
              uiHelper.setCookie('language', langObj[0].cd)
              locale.setCurrentLanguage(langObj[0].cd)
              uiHelper.removeCookie('changeLanguage')
              uiHelper.setCookie('changeLanguage', true)
              uiHelper.setCurrency(cur)

              let url = window.location.href.split('?')[0]
              window.location.href = url + '?af=' + af
            }
          }
        }
      }
    },
    checkReferCode(isSameCurLang) {
      let r = this.$route.query.refer_code
      let af = this.$route.query.af
      let u_s = this.$route.query.utm_source
      let u_m = this.$route.query.utm_medium
      let u_c = this.$route.query.utm_campaign
      let s2 = this.$route.query.s2
      let gnClickId = this.$route.query.click_id //grindclickid
      let lang = this.$route.query.lang
      let cur = this.$route.query.cur
      let uid = this.$route.query.uid //clickid
      let pid = this.$route.query.sub1
      let ab_click_id = this.$route.query.ref_id
      let stag = this.$route.query.stag
      let tracking_link = this.$route.query.tracking_link
      let no_reg = this.$route.query.noreg

      try {
        let old_af = uiHelper.getCookie(SESSION.AFF_CODE)
        if (typeof old_af != 'undefined' && old_af != '' && old_af != null) {
          if (old_af !== af) {
            //if old af not match clear all pixel info.
            uiHelper.removeCookie(SESSION.PID)
            uiHelper.removeCookie(SESSION.CLICKID)
            uiHelper.removeCookie(SESSION.UTM_SOURCE)
            uiHelper.removeCookie(SESSION.UTM_MEDIUM)
            uiHelper.removeCookie(SESSION.UTM_CAMPAIGN)
            uiHelper.removeCookie(SESSION.UTM_S2)
            uiHelper.removeCookie(SESSION.GN_CLICKID)
          }
        }
      } catch (err) {
        console.log(err)
      }

      if (typeof stag != 'undefined' && stag != '' && stag != null) {
        uiHelper.setCookie(SESSION.STAG, stag, 1)
      }
      if (typeof tracking_link != 'undefined' && tracking_link != '' && tracking_link != null) {
        uiHelper.setCookie(SESSION.TRACKING_LINK, tracking_link, 1)
      }

      if (typeof pid != 'undefined' && pid != '' && pid != null) {
        uiHelper.setCookie(SESSION.PID, pid, 1)
      }

      if (typeof uid != 'undefined' && uid != '' && uid != null) {
        uiHelper.setCookie(SESSION.CLICKID, uid, 1)
      }

      if (typeof ab_click_id != 'undefined' && ab_click_id != '' && ab_click_id != null) {
        uiHelper.setCookie(SESSION.CLICKID, ab_click_id, 1)
      }

      if (typeof gnClickId != 'undefined' && gnClickId != '' && gnClickId != null) {
        uiHelper.setCookie(SESSION.GN_CLICKID, gnClickId, 1)
      }

      if (typeof u_s != 'undefined' && u_s != '' && u_s != null) {
        uiHelper.setCookie(SESSION.UTM_SOURCE, u_s, 1)
      }
      if (typeof u_m != 'undefined' && u_m != '' && u_m != null) {
        uiHelper.setCookie(SESSION.UTM_MEDIUM, u_m, 1)
      }
      if (typeof u_c != 'undefined' && u_c != '' && u_c != null) {
        uiHelper.setCookie(SESSION.UTM_CAMPAIGN, u_c, 1)
      }
      if (typeof s2 != 'undefined' && s2 != '' && s2 != null) {
        uiHelper.setCookie(SESSION.UTM_S2, s2, 1)
      }
      if (lang && cur && af && !isSameCurLang) {
        /*skip open */
        return
      } else {
        if (typeof af != 'undefined' && af != '' && af != null) {
          uiHelper.setCookie(SESSION.AFF_CODE, af, 365)
          console.log('openDialog', '1')
          if (no_reg != 'true') {
            this.openRegisterDialog()
          }
        } else {
          if (typeof r != 'undefined' && r != '' && r != null) {
            uiHelper.setCookie(SESSION.REFER_CODE, r, 365)
            console.log('openDialog', '2')
            this.openRegisterDialog()
          }
        }
      }
    },
    handleVendorClick(provider) {
      if (!this.isGameMaintenance(this.providerSelected, provider.providerCode)) {
        this.goToVendor(provider.providerCode)
      }
    },
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    changeMetaTag() {
      let title = '' // locale.getMessage(`meta.default_title`)
      let desc = '' // locale.getMessage(`meta.default_description`)
      let keywords = '' // locale.getMessage(`meta.default_keyword`)
      switch (this.$route.name) {
        case this.routeName.CASINO:
        case this.routeName.CASINO_BY_PROVIDER:
          title = locale.getMessage(`meta.casino_title`)
          desc = locale.getMessage(`meta.casino_description`)
          keywords = locale.getMessage(`meta.casino_keyword`)
          break
        case this.routeName.SPORTSBOOK:
        case this.routeName.SPORTSBOOK_BY_PROVIDER:
          title = locale.getMessage(`meta.sportsbook_title`)
          desc = locale.getMessage(`meta.sportsbook_description`)
          keywords = locale.getMessage(`meta.sportsbook_keyword`)
          break
        case this.routeName.CRICKET:
        case this.routeName.CRICKET_BY_PROVIDER:
          title = locale.getMessage(`meta.default_title`)
          desc = locale.getMessage(`meta.default_description`)
          keywords = locale.getMessage(`meta.default_keyword`)
          break
        case this.routeName.TABLE_GAMES:
        case this.routeName.TABLE_GAMES_BY_PROVIDER:
          title = locale.getMessage(`meta.default_title`)
          desc = locale.getMessage(`meta.default_description`)
          keywords = locale.getMessage(`meta.default_keyword`)
          break
        case this.routeName.FISHING:
        case this.routeName.FISHING_BY_PROVIDER:
          title = locale.getMessage(`meta.fishing_title`)
          desc = locale.getMessage(`meta.fishing_description`)
          keywords = locale.getMessage(`meta.fishing_keyword`)
          break
        case this.routeName.CRASH:
        case this.routeName.CRASH_BY_PROVIDER:
          title = locale.getMessage(`meta.crash_title`)
          desc = locale.getMessage(`meta.crash_description`)
          keywords = locale.getMessage(`meta.crash_keyword`)
          break
        default:
          title = locale.getMessage(`meta.default_title`)
          desc = locale.getMessage(`meta.default_description`)
          keywords = locale.getMessage(`meta.default_keyword`)
      }
      uiHelper.setMetaTag(title, desc, keywords)
    },
    goToPrevGamePage() {
      // let currentRoute = this.$route.path.split('/')[1]
      //
      // let prevRoute = ''
      // let targetPageIndex = this.pageNav.indexOf(currentRoute)-1
      //
      // if(targetPageIndex!=-1){
      //   this.$router.push({
      //     name :  this.pageNav[targetPageIndex],
      //   })
      // }
      const pathSegments = this.$route.path.split('/')
      let langCode = ''
      const currentRoute = pathSegments[pathSegments.length - 1] // Get the last segment
      // Check if the language code exists in the URL
      if (pathSegments.length > 2) {
        const potentialLangCode = pathSegments[1] // Get the potential language code
        if (/^[a-z]{2}-[a-z]{2}$/i.test(potentialLangCode)) {
          // Check if it matches the pattern "xx-xx" (two letters, hyphen, two letters)
          langCode = potentialLangCode // Assign langCode if it matches the pattern
        }
      }

      const targetPageIndex = this.pageNav.indexOf(currentRoute) - 1
      if (targetPageIndex !== -1) {
        const routeParams = {
          name: this.pageNav[targetPageIndex]
        }
        // Only include langCode in params if it exists
        if (langCode) {
          routeParams.params = {
            lang: langCode
          }
        }

        this.$router.push(routeParams)
      }
    },
    goToNextGamePage() {
      const pathSegments = this.$route.path.split('/')
      let langCode = ''
      const currentRoute = pathSegments[pathSegments.length - 1] // Get the last segment
      // Check if the language code exists in the URL
      if (pathSegments.length > 2) {
        langCode = pathSegments[1] // Assuming the language code is always the second segment\\
      }
      const targetPageIndex = this.pageNav.indexOf(currentRoute) + 1
      if (targetPageIndex !== -1) {
        const routeParams = {
          name: this.pageNav[targetPageIndex]
        }

        // Only include langCode in params if it exists
        if (langCode) {
          routeParams.params = {
            lang: langCode
          }
        }

        this.$router.push(routeParams)
      }
    },
    getGameProviderTypeInfo() {
      let providerTypeConfigObj = {
        currency: uiHelper.getCurrency(),
        platform: uiHelper.getPlatform()
      }
      this.$store.dispatch(`${GAME_PROVIDERS_TYPE_CONFIG}`, { providerTypeConfigObj })
    },
    setProviderList() {
      let pageProvider = this.providerSelected
      if (this.storageGameProviderType != undefined) {
        let provider = this.storageGameProviderType.find(function(_providerObj) {
          return _providerObj.type.toLowerCase() == pageProvider
        })
        if (provider != undefined && provider.providers != undefined && provider.providers.length > 0) {
          this.gameProvider = provider.providers
        }
      } else {
        this.getGameProviderTypeInfo()
      }
    },
    searchGame() {
      this.getFilteredGame(1)
    },
    isGameMaintenance(gameType, providerCode) {
      if (
        this.storageGameProviderType == null ||
        this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
        this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance
      }
    },
    getGame() {
      this.searchCriteria.pageNumber = 1
      this.searchCriteria.provider = this.$route.params.provider != 'all' ? this.$route.params.provider : 'all'
      if (this.storageRngGames == null) {
        let Obj = {
          currency: uiHelper.getCurrency(),
          type: this.providerSelected,
          size: '',
          page: '',
          platform: uiHelper.getPlatform(),
          category: '',
          provider: '',
          mode: 'All'
        }
        this.$store.dispatch(`${GAME_TYPE_GAMES}`, {
          Obj
        })
      } else {
        this.getFilteredGame(1)
      }
    },
    getGameCategories() {
      this.$store.dispatch(`${GAME_CATEGORIES}`)
    },
    getGameName(gameLocale) {
      try {
        return gameLocale.find(x => x.language == this.currentLanguage).name
      } catch (error) {
        return gameLocale.find(x => x.language == 'en').name
      }
    },
    getFilteredGame(targetPage) {
      this.metaTag()
      let gameCetegory = this.selectedCategory
      this.searchCriteria.pageNumber = targetPage
      let d = this.storageRngGames
      if (this.searchValue != null && this.searchValue != '') {
        if (this.searchValue.name != '' && this.searchValue.name != null) {
          let filtered = this.searchValue.name
          let p = _.filter(d, function(obj) {
            return obj.locale[0].name.toLowerCase().indexOf(filtered.toLowerCase()) > -1
          })
          d = p
        }
      } else {
        if (gameCetegory != 'all') {
          let p = this.storageRngGames.filter(function(el) {
            return el.category.filter(x => x.name.toLowerCase() == gameCetegory).length > 0
          })
          d = p
        }
        if (this.searchCriteria.provider != 'all' && this.searchCriteria.provider != '' && this.searchCriteria.provider != undefined) {
          let p = d.filter(x => x.provider == this.searchCriteria.provider)
          d = p
        }
      }
      d.forEach(x => {
        this.searchableGameList.push({
          provider: x.provider,
          id: x.id,
          name: x.locale[0].name,
          gamecode: x.code,
          type: x.type
        })
      })
      if (this.$vuetify.breakpoint.xsOnly) {
        this.gameList = d.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize, this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize)
        this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.mobilePageSize)
      } else if (this.$vuetify.breakpoint.mdOnly) {
        this.gameList = d.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.tabletPageSize, this.searchCriteria.pageNumber * this.searchCriteria.tabletPageSize)
        this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.tabletPageSize)
      } else if (this.$vuetify.breakpoint.lgAndUp && (this.currentRouteName === ROUTE_NAME.CASINO || this.currentRouteName === ROUTE_NAME.CASINO_BY_PROVIDER)) {
        this.gameList = d.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.tabletPageSize, this.searchCriteria.pageNumber * this.searchCriteria.tabletPageSize)
        this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.tabletPageSize)
      } else {
        this.gameList = d.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize)
        this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.pageSize)
      }
    },
    changeGameCategory(gameCetegory) {
      this.gameFilterSheet = false
      this.selectedCategory = gameCetegory
      this.getFilteredGame(1)
      this.pagination.currentPage = 1
    },
    changePage(targetPage) {
      this.getFilteredGame(targetPage)
    },
    async directLaunchGame(gameInfo, isDemo) {
      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: gameInfo.provider,
          type: gameInfo.type,
          lang: this.$route.params.lang
        },
        query: {
          id: gameInfo.id,
          code: gameInfo.code,
          demo: isDemo ? 1 : 0
        }
      })

      try {
        this.$ga.event('Game', `${isDemo ? 'Demo' : 'Real'} Play`, `${gameInfo.provider.toUpperCase()} - Slot - ${gameInfo.locale.find(x => x.language == 'en').name}`, gameInfo.id)
      } catch (err) {
        console.log(err)
      }
      if (this.providerSelected == 'sb' || this.providerSelected == 'cricket') {
        window.open(routeData.href, '_blank')
        this.semiTransfer(gameInfo)
      } else {
        window.open(routeData.href, '_blank')
        this.semiTransfer(gameInfo).then(() => {})
      }
    },
    launchGame(gameInfo, isDemo) {
      if (!isDemo && !this.isLoggedIn) {
        this.$parent.goToLoginPage()
      } else {
        uiHelper.pushSportRadarRetargeting(gameInfo, gameInfo.type, gameInfo.provider)
        this.directLaunchGame(gameInfo, isDemo)
      }
    },
    semiTransfer(gameInfo) {
      let obj = {
        to_wallet: gameInfo.provider
      }
      return this.$store.dispatch(`${SEMI_TRANSFER}`, { obj })
    },
    goToVendor(providerCode) {
      this.metaTag()
      this.gameFilterSheet = false
      // if (!this.isLoggedIn && this.isTransferWallet) {
      //     this.$parent.openLoginDialog()
      // } else {
      //
      // }
      if (this.providerSelected == 'sb') {
        this.$router.push({
          name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (this.providerSelected == 'fishing') {
        this.$router.push({
          name: ROUTE_NAME.FISHING_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (this.providerSelected == 'ld') {
        this.$router.push({
          name: ROUTE_NAME.CASINO_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (this.providerSelected == 'lottery') {
        this.$router.push({
          name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (this.providerSelected == 'table') {
        this.$router.push({
          name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (this.providerSelected == 'cricket') {
        this.$router.push({
          name: ROUTE_NAME.CRICKET_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      } else if (this.providerSelected == 'crash') {
        this.$router.push({
          name: ROUTE_NAME.CRASH_BY_PROVIDER,
          params: {
            provider: providerCode,
            lang: this.$route.params.lang
          }
        })
      }
    },
    checkCategory(gameCategory) {
      let isHot = _.findIndex(gameCategory, ['name', 'Hot']) >= 0
      let isNew = _.findIndex(gameCategory, ['name', 'New']) >= 0
      let newIcon = '/static/svg/game-icon-new.svg'
      let hotIcon = '/static/image/other/hot-icon.png'

      if (this.$vuetify.breakpoint.smAndDown) {
        newIcon = '/static/svg/game-icon-new-mobile.svg'
        hotIcon = '/static/image/other/hot-icon.png'
      }

      if (this.selectedCategory != 'hot' && this.selectedCategory != 'new') {
        if (isNew) {
          //return format('{0}/category_icons/New.svg', this.mediaUrl);
          return newIcon
        } else if (isHot) {
          //return format('{0}/category_icons/Hot.svg', this.mediaUrl);
          return hotIcon
        } else {
          return null
        }
      } else if (this.selectedCategory == 'hot' && isHot) {
        return hotIcon
      } else if (this.selectedCategory == 'new' && isNew) {
        return newIcon
      } else {
        return null
      }
    },
    hoverPlayButton(element, mouseOver) {
      if (mouseOver) {
        element.src = '/static/svg/play_btn_hover.svg'
      } else {
        element.src = '/static/svg/play_btn.svg'
      }
    },
    hoverDemoButton(element, mouseOver) {
      if (mouseOver) {
        element.src = '/static/svg/demo_btn_hover.svg'
      } else {
        element.src = '/static/svg/demo_btn.svg'
      }
    }
  }
}
</script>

<style lang="scss">
.disableBackground {
  background-color: #d3d3d3 !important;
}
.search_size {
  max-width: 300px;
  border-radius: 25px;
  padding-left: 15px !important;
}
.search_game_category.v-text-field--enclosed .v-text-field__details {
  min-height: 0px !important;
  margin-bottom: 0px !important;
  height: 0px;
}

.search_size.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 35px !important;
  margin-top: 13px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: none !important;
}

.disableBackground {
  background-color: #d3d3d3 !important;
}
.gameButton {
  height: 150px !important;
  width: 150px !important;
}
.provider_btn {
  max-width: 25px !important;
}
.game_banner_text {
  position: absolute;
  top: 25%;
  left: 23%;
  width: 500px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 30px;
  text-align: left;
}
.selectedProvider {
  background-color: #ffce01 !important;
  color: #000 !important;
}
.betNowButton {
  width: 150px !important;
  border-radius: 32px !important;
  background-color: #ffce01;
  color: #000 !important;
}
.margin-desktop {
  margin: 0px 12%;
}
.mobile-padding {
  padding: 12px 0px;
}
.provider-card-img {
  border-radius: 10px;
}

.mobile-btn {
  background-color: #333333;
  color: var(--v-newMobilePrimary-base) !important;
}

.mobile-category-filter {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.mobile-category-btn {
  border: 2px solid #ffce01;
  margin-bottom: 10px;
}

.mobile-provider-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  background-color: #f5f5f5;
  gap: 1.4rem 1rem;
  padding: 12px 10px;

  .mobile-provider-card {
    display: flex;
    flex-direction: column;
    width: 60px;
    justify-content: flex-start;

    .all-provider {
      width: 60px;
      margin-top: auto;
      margin-bottom: auto;
    }

    &.all-provider-div {
      border-radius: 15px;
      border: 2px solid #ccc;
      max-height: 80px;
      height: 60px;
    }

    &.selected {
      background-color: #333333;
      color: var(--v-newMobilePrimary-base);
    }

    .mobile-vendor-name {
      font-size: 0.68rem;
    }
  }

  ::-webkit-scrollbar-thumb {
    border-radius: unset;
  }

  ::-webkit-scrollbar {
    height: 10px;
  }
}

.mobile-provider-list::-webkit-scrollbar {
  height: 10px;
}

.mobile-provider-list::-webkit-scrollbar-thumb {
  border-radius: unset;
  background: #ffce01;
  -webkit-box-shadow: unset;
}

.mobile-provider-list::-webkit-scrollbar-track {
  -webkit-box-shadow: unset;
  border-radius: 0px;
}

.mobile-search-box {
  display: flex;
  align-items: center;
  justify-content: start;
}

.mobile-filter {
  display: flex;
  margin-top: 15px;
  justify-content: end;
}
.mobile-header-navArrow {
  height: 48px;
  color: var(--v-newMobilePrimary-base) !important;
  padding: 0px 10px;
}
.game-page-card-menu.v-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  font-size: 1.4rem;
  max-height: 80px;
  background-color: #000000;
  color: var(--v-newMobilePrimary-base);

  .game-menu-title {
    font-size: 24px;
    text-transform: capitalize;

    &:hover {
      color: #0c2aab;
    }
  }

  .page-navigator-item {
    &.v-btn--active {
      &::before {
        opacity: 0;
      }

      .underline_bar {
        opacity: 1;
      }
    }
  }

  .game-menu-divider {
    border: 2px solid #fddd04;
    width: 80%;
  }
}

.under-maintenance-text {
  font-size: 16px;
}
.search_game_text > .v-input__control > .v-input__slot {
  font-size: medium;
}
.gamesCardContainer {
  height: 100%;
  //border-radius: 20px !important;
}

.vendor-menu-divider {
  border: 2px solid #fddd04 !important;
}

.vendor_small_icon {
  width: 40px;
  height: 40px;
  right: 1%;
  position: absolute;
  top: 1%;
}

.search_game_text {
  &.v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: 35px;
    margin-top: 13px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #959595 !important;
    box-shadow: none !important;
  }
  .v-label {
    display: flex;
    align-items: center;
  }
  .v-input__icon {
    display: none !important;
  }
  .v-input__append-outer > .v-input__icon > .v-icon {
    position: absolute;
    font-size: 35px;
    top: 25%;
  }

  .v-text-field input {
    font-size: 14px !important;
  }
}

.game_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 14px;
    }
  }
}

.icon_position_hot {
  background-position: 0px 8px;
  left: -7px;
  top: 8px;
  width: 100px;
  height: 50px;

  &.isMobile {
    width: 75px;
    height: 35px;
  }
}

.icon_position_new {
  background-position: 0px 8px;
  left: -7px;
  top: -7px;
  width: 100px;
  height: 100px;

  &.isMobile {
    left: -4px;
    top: 0px;
    width: 50px;
    height: 40px;
  }
}

.buttonGold {
  background-image: linear-gradient(to right, var(--v-buttonGold_primary-base), var(--v-buttonGold_secondary-base));
}

.v-chip.v-size--default.provider_chips {
  height: 20px;
}

.game-filter-sheet-header {
  background-image: linear-gradient(to right, var(--v-primary-base), var(--v-primary_3-base));
  padding: 15px 0;
  position: relative;

  .game-filter-sheet-close-icon {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 35px;
  }

  p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0 !important;
  }
}

.game-filter-sheet-body {
  position: relative;
  overflow: scroll;
  height: 100%;

  .game-filter-sheet-body-content {
    padding: 15px;

    .game-filter-vendor-type-item-box {
      padding-top: 25px;

      .game-filter-vendor-type-item {
        font-size: 20px;
        padding: 10px;
        display: inline-block;

        p {
          font-size: 18px;
          border-radius: 5px;
          padding: 8px 25px;
          border: 3px solid var(--v-primary_2-base);
          margin: 0px !important;

          &.active {
            background-color: var(--v-primary_2-base);
          }
        }
      }
    }

    .game-filter-sheet-body-content-text {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0 !important;
    }
  }
}

.game-filter-display {
  padding: 10px 15px;
}

.game-filter-display-text {
  font-size: 14px;
  margin-bottom: 0 !important;
}

.game-filter-button {
  background: linear-gradient(180deg, #273270 0%, #1c244f 100%);
  border-radius: 5px;
  width: 90px !important;
  font-weight: bold;
  min-width: 90px !important;
  padding: 5px 0px !important;

  .v-icon {
    font-size: 28px;
    padding: 0px;
  }
}

.slot-game-card-select {
  .slot-game-card-item {
    padding: 0px 10px !important;
    display: inline-block;
    width: 12.5%;
  }
  .casino-game-card-item {
    padding: 0px 10px !important;
    display: inline-block;
    width: 12.5%;
  }
  .v-card {
    position: relative;
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;

    .slot-game-image-box {
      width: 100%;
      min-width: auto !important;
      min-height: auto !important;
      height: 205px;
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px !important;
    }

    .slot-game-button-container {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      //background-color: white;
      opacity: 0;

      .slot-game-button-container-box {
        position: relative;
        width: 100%;
        height: 100%;

        .slot-game-button-box {
          height: auto;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          // .slot-game-bet-button {
          //   width: 200px;
          //   margin: 5px 0 !important;
          // }
          // .slot-game-demo-button {
          //   width: 200px;
          //   background-color: transparent !important;
          //   box-shadow: none;
          //   margin: 5px 0 !important;
          //   &:before {
          //     opacity: 0;
          //   }
          // }
        }
      }
    }

    &.isMaintenance {
      .slot-game-button-container {
        opacity: 1;
      }
    }

    &:hover {
      .slot-game-button-container {
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.65);
      }
    }
  }

  .slot-game-name-box {
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    height: 2.2em;
    white-space: nowrap;
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

.game-vendor-list-container {
  padding: 0px;
  background-color: #f3f5f6;
  margin: 20px 0px !important;
  border-radius: 5px;
}

.game-category-list-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100vw;
  //background-color: #262626;
  text-transform: capitalize !important;

  .category-divider {
    background-color: #acacac;
  }
}

.vendor-list-item.slot-product {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-basis: calc(16.6%);
  padding: 10px;

  button {
    border-radius: 25px !important;
  }

  .vendor-list-item-button {
    background-color: #fff;
    border: 2px solid #ffce01;
    width: 100% !important;
    letter-spacing: 0;
    min-width: 70px !important;
    color: #6c6c6c;
    // &.active {
    //   background-image: linear-gradient(to right, var(--v-buttonGold_primary-base), var(--v-buttonGold_secondary-base));
    // }
  }
}

.slot-header {
  position: relative;
}

.game-filter-display {
  display: none;
}

@media (max-width: 1920px) {
  .slot-game-card-select .slot-game-card-item {
    width: 12.5%;
  }
}

@media (max-width: 1650px) {
  .jackpot-title {
    font-size: 50px;
  }
  .jackpot-char-box {
    font-size: 75px;
  }
  .slot-game-card-select .v-card .slot-game-image-box {
    height: 170px;
  }
}

@media (max-width: 1650px) {
  .slot-game-card-select .slot-game-card-item {
    width: 16%;
  }
  .slot-game-card-select .casino-game-card-item {
    width: 16%;
  }
}

@media (max-width: 1500px) {
  .jackpot-title {
    font-size: 40px;
  }
  .jackpot-char-box {
    font-size: 60px;
  }
  .jackpot-char-box.num {
    width: 70px;
  }
  .vendor-list-item .vendor-list-item-button {
    min-width: 100px !important;

    .v-btn__content {
      font-size: 14px;
    }
  }
  .slot-game-card-select .slot-game-card-item {
    width: 20%;
  }
  .slot-game-card-select .casino-game-card-item {
    width: 20%;
  }
  .slot-game-card-select .v-card .slot-game-image-box {
    height: 195px;
  }
}

@media (max-width: 1350px) {
  .slot-game-name-box {
    padding: 8px 16px !important;
  }
  .slot-game-card-select .v-card .slot-game-button-container .slot-game-button-container-box .slot-game-button-box .slot-game-bet-button,
  .slot-game-card-select .v-card .slot-game-button-container .slot-game-button-container-box .slot-game-button-box .slot-game-demo-button {
    width: 150px;
    margin: 5px 0 !important;
    padding: 10px !important;

    .v-btn__content {
      font-size: 14px;
    }
  }
  .slot-game-card-select .v-card .slot-game-image-box {
    height: 175px;
  }
}

@media (max-width: 1263px) {
  .provider_btn {
    max-width: 20px !important;
  }
  .slot-game-card-select .v-card .slot-game-image-box {
    height: 140px;
  }
}

@media (max-width: 1115px) {
  .vendor-list-item .vendor-list-item-button .v-btn__content {
    font-size: 12px;
  }
  .slot-game-card-select .slot-game-card-item {
    width: 25%;
  }
  .slot-game-card-select .casino-game-card-item {
    width: 25%;
  }
}

@media (max-width: 959px) {
  .slot-game-card-select .slot-game-card-item {
    width: 25%;
  }
  .slot-game-card-select .casino-game-card-item {
    width: 25%;
  }
  .mobile-provider-card {
    font-size: 0.85rem !important;
  }
  .gamesCardContainer.v-card {
    border-radius: unset !important;
  }
  .vendor-list-item .vendor-list-item-button .v-btn__content {
    font-size: 14px;
  }
  .game-vendor-list-container {
    padding: 0px;
  }
  // .game-category-list-container {
  //   display: none;
  // }
  .vendor-list-item {
    .vendor-list-item-button {
      box-shadow: none !important;
      border-radius: 0px !important;
    }
  }
  .vendor-list-item .vendor-list-item-button.active {
    background-image: none;
    color: var(--v-secondary-base) !important;
    font-weight: bold !important;
    border-bottom: 2px solid;
    padding-bottom: 2px !important;
    border-color: var(--v-secondary-base) !important;
  }
  .vendor-list-item .vendor-list-item-button {
    min-width: auto !important;
    padding: 0px !important;
  }
  .game-filter-display {
    display: block;
  }
  .slot-game-card-select .v-card .slot-game-image-box {
    height: 180px;
  }
  .slot-game-card-select .v-card .slot-game-button-container .slot-game-button-container-box .slot-game-button-box .slot-game-bet-button,
  .slot-game-card-select .v-card .slot-game-button-container .slot-game-button-container-box .slot-game-button-box .slot-game-demo-button {
    width: 125px;
    margin: 0px 0 !important;
    padding: 10px 0 !important;
  }
}

@media (max-width: 599px) {
  .gameButton {
    height: 100px !important;
    width: 100px !important;
    min-width: 100px !important;
  }
  .under-maintenance-text {
    font-size: 12px;
  }
  .game-page-card-menu {
    max-height: 80px;
    border-radius: 10px !important;

    .game-menu-title {
      font-size: 12px;
      text-transform: capitalize;

      &:hover {
        color: #0c2aab;
      }
    }

    .game-menu-divider {
      border: 2px solid #fddd04;
    }
  }
  .game-vendor-list-container {
    padding: 0px;

    .vendor-list-item {
      margin-bottom: 0px;
    }
  }
  .slot-game-card-select .slot-game-card-item {
    width: 33.33%;
  }
  .slot-game-card-select .casino-game-card-item {
    width: 33.33%;
  }
  .slot-game-card-select .v-card .slot-game-image-box {
    height: 170px;
  }
  .slot-game-name-box {
    padding: 4px 16px !important;
    font-size: 12px;
  }
  .slot-game-card-select .v-card .slot-game-button-container .slot-game-button-container-box .slot-game-button-box .slot-game-bet-button,
  .slot-game-card-select .v-card .slot-game-button-container .slot-game-button-container-box .slot-game-button-box .slot-game-demo-button {
    width: 80px;
    margin: 5px 0 !important;
    padding: 8px 10px !important;

    .v-btn__content {
      font-size: 12px;
    }
  }
}

@media (min-width: 500px) {
  .full_width {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 425px) {
  .game-filter-sheet-body .game-filter-sheet-body-content .game-filter-vendor-type-item-box .game-filter-vendor-type-item p {
    font-size: 16px;
    padding: 5px 20px;
  }
  .game-filter-sheet-body .game-filter-sheet-body-content .game-filter-vendor-type-item-box .game-filter-vendor-type-item {
    padding: 10px 5px;
  }
  .slot-game-card-select .v-card .slot-game-image-box {
    height: 120px;
  }
}

.new_icon_sizing {
  left: -1px;
  bottom: 22px;
}
</style>
